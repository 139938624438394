@keyframes hotToastEnter {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.hot-toast-animated-icon {
  animation: var(--hot-toast-animated-icon-animation, hotToastEnter 0.3s ease-in-out forwards);
  position: var(--hot-toast-animated-icon-position, relative);
  transform: var(--hot-toast-animated-icon-transform, scale(0));
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-animated-icon {
    animation-duration: var(--hot-toast-animated-icon-reduced-motion-animation-duration, none);
    opacity: var(--hot-toast-animated-icon-reduced-motion-opacity, 1);
    transform: var(--hot-toast-animated-icon-reduced-motion-transform, scale(1));
  }
}

@keyframes hotToastCircleAnimation {
  from {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
}
@keyframes hotToastCheckmarkAnimation {
  0% {
    height: 0;
    opacity: 0;
    width: 0;
  }
  40% {
    height: 0;
    opacity: 1;
    width: 6px;
  }
  100% {
    height: 10px;
    opacity: 1;
  }
}
.hot-toast-checkmark-icon {
  animation: var(--hot-toast-checkmark-icon-animation, hotToastCircleAnimation 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards);
  animation-delay: var(--hot-toast-checkmark-icon-animation-delay, 100ms);
  background-color: var(--hot-toast-checkmark-icon-background-color, var(--check-primary, #61d345));
  border-radius: var(--hot-toast-checkmark-icon-border-radius, 10px);
  height: var(--hot-toast-checkmark-icon-height, 20px);
  opacity: var(--hot-toast-checkmark-icon-opacity, 0);
  position: var(--hot-toast-checkmark-icon-position, relative);
  transform: var(--hot-toast-checkmark-icon-transform, rotate(45deg));
  width: var(--hot-toast-checkmark-icon-width, 20px);
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-checkmark-icon {
    animation-duration: var(--hot-toast-checkmark-icon-reduced-motion-animation-duration, 0ms);
  }
}

.hot-toast-checkmark-icon::after {
  animation: var(--hot-toast-checkmark-icon-after-animation, hotToastCheckmarkAnimation 0.2s ease-out forwards);
  animation-delay: var(--hot-toast-checkmark-icon-after-animation-delay, 200ms);
  border-bottom-style: solid;
  border-bottom-width: var(--hot-toast-checkmark-icon-after-border-bottom, 2px);
  border-color: var(--hot-toast-checkmark-icon-after-border-color, var(--check-secondary, #fff));
  border-right-style: solid;
  border-right-width: var(--hot-toast-checkmark-icon-after-border-right, 2px);
  bottom: var(--hot-toast-checkmark-icon-after-bottom, 6px);
  box-sizing: var(--hot-toast-checkmark-icon-after-box-sizing, border-box);
  content: var(--hot-toast-checkmark-icon-after-content, "");
  height: var(--hot-toast-checkmark-icon-after-height, 10px);
  left: var(--hot-toast-checkmark-icon-after-left, 6px);
  opacity: var(--hot-toast-checkmark-icon-after-opacity, 0);
  position: var(--hot-toast-checkmark-icon-after-position, absolute);
  width: var(--hot-toast-checkmark-icon-after-width, 6px);
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-checkmark-icon::after {
    animation-duration: var(--hot-toast-checkmark-icon-after-reduced-motion-animation-duration, 0ms);
  }
}

@keyframes hotToastErrorCircleAnimation {
  from {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
}
@keyframes hotToastFirstLineAnimation {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes hotToastSecondLineAnimation {
  from {
    opacity: 0;
    transform: scale(0) rotate(90deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(90deg);
  }
}
.hot-toast-error-icon {
  animation: var(--hot-toast-error-icon-animation, hotToastErrorCircleAnimation 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards);
  animation-delay: var(--hot-toast-error-icon-animation-delay, 100ms);
  background: var(--hot-toast-error-icon-background, var(--error-primary, #ff4b4b));
  border-radius: var(--hot-toast-error-icon-border-radius, 10px);
  height: var(--hot-toast-error-icon-height, 20px);
  opacity: var(--hot-toast-error-icon-opacity, 0);
  position: var(--hot-toast-error-icon-position, relative);
  transform: var(--hot-toast-error-icon-transform, rotate(45deg));
  width: var(--hot-toast-error-icon-width, 20px);
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-error-icon {
    animation-duration: var(--hot-toast-error-icon-reduced-motion-animation-duration, 0ms);
  }
}

.hot-toast-error-icon::after,
.hot-toast-error-icon::before {
  animation: var(--hot-toast-error-icon-after-before-animation, hotToastFirstLineAnimation 0.15s ease-out forwards);
  animation-delay: var(--hot-toast-error-icon-after-before-animation-delay, 150ms);
  background: var(--hot-toast-error-icon-after-before-background, var(--error-secondary, #fff));
  border-radius: var(--hot-toast-error-icon-after-before-border-radius, 3px);
  bottom: var(--hot-toast-error-icon-after-before-bottom, 9px);
  content: var(--hot-toast-error-icon-after-before-content, "");
  height: var(--hot-toast-error-icon-after-before-height, 2px);
  left: var(--hot-toast-error-icon-after-before-left, 4px);
  opacity: var(--hot-toast-error-icon-after-before-opacity, 0);
  position: var(--hot-toast-error-icon-after-before-position, absolute);
  width: var(--hot-toast-error-icon-after-before-width, 12px);
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-error-icon::after,
  .hot-toast-error-icon::before {
    animation-duration: var(--hot-toast-error-icon-after-before-reduced-motion-animation-duration, 0ms);
  }
}

.hot-toast-error-icon::before {
  animation: var(--hot-toast-error-icon-before-animation, hotToastSecondLineAnimation 0.15s ease-out forwards);
  animation-delay: var(--hot-toast-error-icon-before-animation-delay, 180ms);
  transform: var(--hot-toast-error-icon-before-transform, rotate(90deg));
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-error-icon::before {
    animation-duration: var(--hot-toast-error-icon-before-reduced-motion-animation-duration, 0ms);
  }
}

.hot-toast-bar-base {
  align-items: var(--hot-toast-align-items, center);
  background-color: var(--hot-toast-bg, #fff);
  border-radius: var(--hot-toast-border-radius, 4px);
  box-shadow: var(--hot-toast-shadow, 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05));
  color: var(--hot-toast-color, #363636);
  display: var(--hot-toast-display, flex);
  line-height: var(--hot-toast-line, 1.3);
  margin: var(--hot-toast-margin, 16px);
  max-width: var(--hot-toast-max-width, 350px);
  padding: var(--hot-toast-padding, 8px 10px);
  pointer-events: var(--hot-toast-pointer-events, auto);
  width: var(--hot-toast-width, fit-content);
  transition-property: border-bottom-left-radius, border-bottom-right-radius;
  transition-duration: 230ms;
  transition-timing-function: ease-out;
}
.hot-toast-bar-base:hover, .hot-toast-bar-base:focus {
  animation-play-state: var(--hot-toast-animation-state, paused) !important;
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-bar-base {
    animation-duration: var(--hot-toast-reduced-motion-animation-duration, 10ms) !important;
  }
}
.expanded .hot-toast-bar-base {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.hot-toast-message {
  color: var(--hot-toast-message-color, inherit);
  display: var(--hot-toast-message-display, flex);
  flex: var(--hot-toast-message-flex, 1);
  justify-content: var(--hot-toast-message-justify-content, center);
  margin: var(--hot-toast-message-margin, 4px 10px);
}

.hot-toast-bar-base-container {
  display: var(--hot-toast-container-display, flex);
  pointer-events: var(--hot-toast-container-pointer-events, none);
  position: var(--hot-toast-container-position, absolute);
  transition: var(--hot-toast-container-transition, transform 230ms cubic-bezier(0.21, 1.02, 0.73, 1));
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-bar-base-container {
    transition-duration: var(--hot-toast-container-reduced-motion-transition-duration, 10ms) !important;
  }
}
.hot-toast-bar-base-container.hot-toast-theme-snackbar .hot-toast-bar-base {
  background: var(--hot-toast-snackbar-bg, #323232);
  box-shadow: var(--hot-toast-snackbar-shadow, 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12));
  color: var(--hot-toast-snackbar-color, rgb(255, 255, 255));
}
.hot-toast-bar-base-container.hot-toast-theme-snackbar .hot-toast-close-btn {
  filter: var(--hot-toast-snackbar-close-btn-filter, invert(1) grayscale(100%) brightness(200%));
}

.hot-toast-close-btn {
  align-self: var(--hot-toast-close-btn-align-self, flex-start);
  background-color: var(--hot-toast-close-btn-background-color, transparent);
  background-image: var(--hot-toast-close-btn-background-image, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e"));
  background-position: var(--hot-toast-close-btn-background-position, center);
  background-repeat: var(--hot-toast-close-btn-background-repeat, no-repeat);
  background-size: var(--hot-toast-close-btn-background-size, 0.75em);
  border: var(--hot-toast-close-btn-border, 0);
  border-radius: var(--hot-toast-close-btn-border-radius, 0.25rem);
  box-sizing: var(--hot-toast-close-btn-box-sizing, content-box);
  display: var(--hot-toast-close-btn-display, flex);
  height: var(--hot-toast-close-btn-height, 0.8em);
  margin-top: var(--hot-toast-close-btn-margin-top, 0.25em);
  opacity: var(--hot-toast-close-btn-opacity, 0.5);
  padding: var(--hot-toast-close-btn-padding, 0.25em);
  width: var(--hot-toast-close-btn-width, 0.8em);
}
.hot-toast-close-btn:focus {
  box-shadow: var(--hot-toast-close-btn-box-shadow, 0 0 0 0.125rem rgba(13, 110, 253, 0.25));
  outline: var(--hot-toast-close-btn-outline, none);
}
.hot-toast-close-btn:hover, .hot-toast-close-btn:focus {
  opacity: var(--hot-toast-close-btn-opacity, 0.75);
}

.hot-toast-group-btn {
  align-self: var(--hot-toast-group-btn-align-self, flex-start);
  background-color: var(--hot-toast-group-btn-background-color, transparent);
  background-image: var(--hot-toast-group-btn-background-image, url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.75745 10.5858L9.17166 9.17154L12.0001 12L14.8285 9.17157L16.2427 10.5858L12.0001 14.8284L7.75745 10.5858Z' fill='currentColor' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z' fill='currentColor' /%3E%3C/svg%3E"));
  background-position: var(--hot-toast-group-btn-background-position, center);
  background-repeat: var(--hot-toast-group-btn-background-repeat, no-repeat);
  background-size: var(--hot-toast-group-btn-background-size, 1.3em);
  border: var(--hot-toast-group-btn-border, 0);
  border-radius: var(--hot-toast-group-btn-border-radius, 0.25rem);
  box-sizing: var(--hot-toast-group-btn-box-sizing, content-box);
  display: var(--hot-toast-group-btn-display, flex);
  height: var(--hot-toast-group-btn-height, 0.8em);
  margin-top: var(--hot-toast-group-btn-margin-top, 0.25em);
  opacity: var(--hot-toast-group-btn-opacity, 0.5);
  padding: var(--hot-toast-group-btn-padding, 0.25em);
  width: var(--hot-toast-group-btn-width, 0.8em);
  will-change: var(--hot-toast-group-btn-will-change, transform);
  transition: var(--hot-toast-group-btn-transition, transform 230ms cubic-bezier(0.21, 1.02, 0.73, 1));
}
.hot-toast-group-btn:focus {
  box-shadow: var(--hot-toast-group-btn-box-shadow, 0 0 0 0.125rem rgba(13, 110, 253, 0.25));
  outline: var(--hot-toast-group-btn-outline, none);
}
.hot-toast-group-btn:hover, .hot-toast-group-btn:focus {
  opacity: var(--hot-toast-group-btn-opacity, 0.75);
}
.expanded .hot-toast-group-btn {
  transform: rotate(var(--hot-toast-group-btn-expanded-rotate, 180deg));
}

.hot-toast-icon {
  align-self: var(--hot-toast-icon-align-self, flex-start);
  padding-top: var(--hot-toast-icon-padding-top, 0.25em);
}

.hot-toast-bar-base-wrapper {
  pointer-events: all;
}

.hot-toast-bar-base-group {
  --hot-toast-shadow: none;
  background-color: var(--hot-toast-group-bg, #fff);
  margin: var(--hot-toast-margin, 16px);
  margin-top: calc(-1 * var(--hot-toast-margin, 16px));
  border-bottom-left-radius: var(--hot-toast-border-radius, 4px);
  border-bottom-right-radius: var(--hot-toast-border-radius, 4px);
  height: 0;
  overflow: hidden;
  transition-property: height;
  transition-duration: 230ms;
  transition-timing-function: ease-in-out;
  position: relative;
  box-shadow: var(--hot-toast-group-after-shadow, 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05));
}
.expanded .hot-toast-bar-base-group {
  height: var(--hot-toast-group-height);
}
.hot-toast-bar-base-group .hot-toast-bar-base {
  margin: 0;
}

@keyframes hotToastEnterAnimationNegative {
  0% {
    opacity: 0.5;
    transform: translate3d(0, -80px, 0) scale(0.6);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes hotToastEnterAnimationPositive {
  0% {
    opacity: 0.5;
    transform: translate3d(0, 80px, 0) scale(0.6);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes hotToastExitAnimationPositive {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, var(--hot-toast-exit-positive-y, 130px), -1px) scale(0.5);
  }
}
@keyframes hotToastExitAnimationNegative {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, var(--hot-toast-exit-negative-y, -130px), -1px) scale(0.5);
  }
}
@keyframes hotToastEnterSoftAnimationNegative {
  0% {
    opacity: 0.5;
    transform: translate3d(0, -14px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes hotToastEnterSoftAnimationPositive {
  0% {
    opacity: 0.5;
    transform: translate3d(0, 14px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes hotToastExitSoftAnimationPositive {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 14px, -1px);
  }
}
@keyframes hotToastExitSoftAnimationNegative {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -14px, -1px);
  }
}
.hot-toast-indicator-wrapper {
  align-items: var(--hot-toast-indicator-wrapper-align-items, center);
  display: var(--hot-toast-indicator-wrapper-display, flex);
  justify-content: var(--hot-toast-indicator-wrapper-justify-content, center);
  min-height: var(--hot-toast-indicator-wrapper-min-height, 20px);
  min-width: var(--hot-toast-indicator-wrapper-min-width, 20px);
  position: var(--hot-toast-indicator-wrapper-position, relative);
}

.hot-toast-status-wrapper {
  position: var(--hot-toast-status-wrapper-position, absolute);
}

@keyframes animate-info-background {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes animate-info-line {
  0% {
    height: 0;
    opacity: 0;
  }
  40% {
    height: 4.8px;
    opacity: 1;
  }
  100% {
    height: 8px;
    opacity: 1;
  }
}
.hot-toast-info-icon {
  animation: var(--hot-toast-info-icon-animation, animate-info-background 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards);
  animation-delay: var(--hot-toast-info-icon-animation-delay, 100ms);
  background-color: var(--hot-toast-info-icon-background-color, var(--info-primary, #0d6efd));
  border-radius: var(--hot-toast-info-icon-border-radius, 50%);
  display: var(--hot-toast-info-icon-display, block);
  height: var(--hot-toast-info-icon-height, 20px);
  opacity: var(--hot-toast-info-icon-opacity, 0);
  position: var(--hot-toast-info-icon-position, relative);
  transform: var(--hot-toast-info-icon-transform, scale(0));
  width: var(--hot-toast-info-icon-width, 20px);
}
.hot-toast-info-icon::after, .hot-toast-info-icon::before {
  background-color: var(--hot-toast-info-icon-after-before-background-color, var(--info-secondary, #fff));
  border-radius: var(--hot-toast-info-icon-after-before-border-radius, 3px);
  box-sizing: var(--hot-toast-info-icon-after-before-box-sizing, border-box);
  content: var(--hot-toast-info-icon-after-before-content, "");
  display: var(--hot-toast-info-icon-after-before-display, block);
  left: var(--hot-toast-info-icon-after-before-left, 8.5px);
  position: var(--hot-toast-info-icon-after-before-position, absolute);
  width: var(--hot-toast-info-icon-after-before-width, 2.5px);
}
.hot-toast-info-icon::after {
  animation: var(--hot-toast-info-icon-after-animation, animate-info-line 0.2s ease-out forwards);
  animation-delay: var(--hot-toast-info-icon-after-animation-delay, 200ms);
  height: var(--hot-toast-info-icon-after-height, 0);
  opacity: var(--hot-toast-info-icon-after-opacity, 0);
  top: var(--hot-toast-info-icon-after-bottom, 8px);
}
.hot-toast-info-icon::before {
  height: var(--hot-toast-info-icon-before-height, 2px);
  top: var(--hot-toast-info-icon-before-top, 4px);
}

@keyframes hotToastRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hot-toast-loader-icon {
  animation: var(--hot-toast-loader-icon-animation, hotToastRotate 1s linear infinite);
  border: var(--hot-toast-loader-icon-border, 2px solid);
  border-bottom-color: var(--hot-toast-loader-icon-border-color, #e0e0e0);
  border-left-color: var(--hot-toast-loader-icon-border-color, #e0e0e0);
  border-radius: var(--hot-toast-loader-icon-border-radius, 100%);
  border-right-color: var(--hot-toast-loader-icon-border-right-color, #616161);
  border-top-color: var(--hot-toast-loader-icon-border-color, #e0e0e0);
  box-sizing: var(--hot-toast-loader-icon-box-sizing, border-box);
  height: var(--hot-toast-loader-icon-height, 18px);
  padding-top: var(--hot-toast-loader-icon-padding-top, 2px);
  width: var(--hot-toast-loader-icon-width, 18px);
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-loader-icon {
    animation-duration: var(--hot-toast-loader-icon-reduced-motion-animation-duration, 5s);
  }
}

@keyframes animate-warn-background {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes animate-warn-line {
  0% {
    height: 0;
    opacity: 0;
  }
  40% {
    height: 4.8px;
    opacity: 1;
  }
  100% {
    height: 8px;
    opacity: 1;
  }
}
.hot-toast-warning-icon {
  animation: var(--hot-toast-warning-icon-animation, animate-warn-background 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards);
  animation-delay: var(--hot-toast-warning-icon-animation-delay, 100ms);
  background-color: var(--hot-toast-warning-icon-background-color, var(--warn-primary, #ffab00));
  border-radius: var(--hot-toast-warning-icon-border-radius, 50%);
  display: var(--hot-toast-warning-icon-display, block);
  height: var(--hot-toast-warning-icon-height, 20px);
  opacity: var(--hot-toast-warning-icon-opacity, 0);
  position: var(--hot-toast-warning-icon-position, relative);
  transform: var(--hot-toast-warning-icon-transform, scale(0));
  width: var(--hot-toast-warning-icon-width, 20px);
}
.hot-toast-warning-icon::after, .hot-toast-warning-icon::before {
  background-color: var(--hot-toast-warning-icon-after-before-background-color, var(--warn-secondary, #fff));
  border-radius: var(--hot-toast-warning-icon-after-before-border-radius, 3px);
  box-sizing: var(--hot-toast-warning-icon-after-before-box-sizing, border-box);
  content: var(--hot-toast-warning-icon-after-before-content, "");
  display: var(--hot-toast-warning-icon-after-before-display, block);
  left: var(--hot-toast-warning-icon-after-before-left, 8.5px);
  position: var(--hot-toast-warning-icon-after-before-position, absolute);
  width: var(--hot-toast-warning-icon-after-before-width, 2.5px);
}
.hot-toast-warning-icon::after {
  animation: var(--hot-toast-warning-icon-after-animation, animate-warn-line 0.2s ease-out forwards);
  animation-delay: var(--hot-toast-warning-icon-after-animation-delay, 200ms);
  height: var(--hot-toast-warning-icon-after-height, 0);
  opacity: var(--hot-toast-warning-icon-after-opacity, 0);
  top: var(--hot-toast-warning-icon-after-top, 4px);
}
.hot-toast-warning-icon::before {
  bottom: var(--hot-toast-warning-icon-before-bottom, 4px);
  height: var(--hot-toast-warning-icon-before-height, 2px);
}

/*# sourceMappingURL=styles.css.map */
