@use "tailwindcss/base" as tb;
@use "tailwindcss/components" as tc;
@use "tailwindcss/utilities" as tu;

@use "@angular/cdk/overlay-prebuilt.css" as op;
@use "node_modules/@ngxpert/hot-toast/src/styles/styles.css" as ht;
@use "@ng-select/ng-select/themes/default.theme.css" as sd;
/* You can add global styles to this file, and also import other style files */

// ng-select
.ng-select.input-ng-select {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  min-height: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  border-radius: 6px;
}

.ng-select.input-ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}

.ng-select:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.ng-select.input-ng-select .ng-select-container {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  min-height: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  border-radius: 6px;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  --tw-text-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-text-opacity));
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px #fde6e8;
}

th[appSortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[appSortable].desc:after,
th[appSortable].asc:after {
  content: "\f062";
  font-family: FontAwesome;
  display: block;
  float: right;
}

th[appSortable].desc:after {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

// tailwindcss

@layer base {
  @font-face {
    font-family: "Cantarell";
    font-weight: 400;
    src: url("assets/fonts/Cantarell/Cantarell-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Cantarell";
    font-weight: 600;
    src: url("assets/fonts/Cantarell/Cantarell-Bold.ttf") format("truetype");
  }
}

@layer components {
  .form-label {
    @apply block text-sm text-gray-600;
  }

  .form-label.required:after {
    content: " *";
    @apply text-red-600;
  }

  .form-input,
  .form-textarea {
    @apply flex-1 block w-full font-medium text-gray-800 bg-yellow-200 border-gray-600 
    focus:ring-gray-700 focus:border-gray-700 rounded-md sm:text-sm
      disabled:opacity-50 invalid:border-red-500 invalid:text-red-600
    focus:invalid:border-red-500 focus:invalid:ring-red-500;
  }

  .form-checkbox {
    @apply w-4 h-4 text-gray-800 bg-yellow-200 border-gray-600 ring-offset-0 ring-offset-gray-700  focus:ring-gray-700 focus:border-gray-700 rounded;
  }

  .form-date {
    @apply flex-1 block font-medium text-gray-800 bg-yellow-200 border-gray-600  focus:ring-gray-700 focus:border-gray-700 rounded-md sm:text-sm;
  }

  .form-search {
    @apply h-8 px-5 py-1 pr-12 text-xs bg-yellow-200 border-2 border-gray-600 rounded-lg focus:ring-gray-700 focus:border-gray-700;
  }

  .form-select {
    @apply h-8 px-5 py-1 pr-12 text-xs text-gray-700  bg-yellow-200 border-2 border-gray-600 rounded-lg focus:ring-gray-700 focus:border-gray-700;
  }

  .form-hint {
    @apply m-1 text-xs text-gray-600;
  }

  .form-error {
    @apply m-1 text-xs text-red-600;
  }

  .form-error-field {
    @apply border border-red-500 focus:border-red-500 focus:ring-red-500 text-red-600;
  }

  .radio-button-field {
    @apply text-gray-800 border-gray-300 cursor-pointer focus:ring-gray-700;
  }

  .list-button {
    @apply relative inline-flex items-center px-2 py-1 text-xs font-medium leading-5 text-yellow-300 transition duration-150 ease-in-out bg-gray-900 border border-gray-600 rounded-md hover:bg-gray-900 hover:text-yellow-500 focus:z-10 focus:outline-none focus:border-gray-700 active:bg-gray-700 active:text-yellow-50 disabled:opacity-30;
  }

  ng-select.form-input {
    .ng-select-container {
      @apply w-full flex flex-row bg-yellow-200 hover:bg-gray-300 border-gray-300  focus:ring-green-700 focus:border-green-700 rounded-md sm:text-sm;
    }
  }

  .error-hint {
    @apply mt-1 text-xs text-red-400;
  }

  .btn {
    @apply px-4 py-2 text-sm disabled:cursor-not-allowed disabled:bg-opacity-50 rounded-lg;
  }

  .btn-flex {
    @apply flex items-center gap-2;
  }

  .btn-accent {
    @apply px-4 py-2 text-sm text-yellow-50 bg-green-700 hover:bg-green-900 focus:border-green-900 disabled:opacity-50 rounded-lg;
  }

  .btn-primary {
    @apply px-4 py-2 text-sm text-yellow-300 bg-gray-900 hover:bg-gray-600 focus:border-gray-600 disabled:opacity-50 rounded-lg;
  }

  .btn-danger {
    @apply py-2 px-4 text-sm text-white bg-red-600 border-red-600 outline-none hover:bg-red-800 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 rounded-lg;
  }

  .btn-outline {
    @apply py-2 px-4 text-sm text-gray-700 border-gray-700 transition duration-150 ease-in-out bg-white border rounded-lg hover:text-purple-500 focus:outline-none focus:shadow-purple-300 focus:border-purple-300 active:text-purple-700 disabled:cursor-not-allowed disabled:opacity-50;
  }

  .btn-auth-social {
    @apply flex items-center justify-center gap-5 rounded-lg border px-4 py-2 text-sm hover:shadow;
  }

  .btn-quick-links {
    @apply border font-semibold inline-flex items-center px-4 py-2 rounded-lg text-gray-700 disabled:opacity-50;
  }

  .quick-links-wrapper {
    @apply inline-block relative text-sm;
  }

  .dropdown-links-parent {
    @apply absolute bg-white invisible group-hover:visible right-0 rounded-lg shadow-lg text-gray-700 text-left w-40 z-10;
  }

  .dropdown-link {
    @apply block py-2 px-4 rounded-t whitespace-nowrap cursor-pointer hover:bg-gray-100 hover:font-normal space-x-2;
  }

  // Table
  .table-wrapper {
    @apply overflow-x-auto border border-gray-300 bg-yellow-50 sm:rounded-lg;
  }

  .table {
    @apply table-fixed min-w-full divide-y divide-gray-300;
  }

  .table-body {
    @apply divide-y divide-gray-300;
  }

  .table-th {
    @apply py-3 px-6 text-xs text-gray-800 text-left font-medium leading-4 tracking-wider uppercase;
  }

  .table-td {
    @apply py-4 px-6 text-xs text-gray-900 uppercase;
  }

  .table-link {
    @apply text-yellow-600 hover:text-yellow-900;
  }

  .table-actions {
    @apply p-4 flex items-center justify-end text-sm whitespace-nowrap;
  }

  .table-cell-sm {
    @apply hidden sm:table-cell;
  }

  .table-cell-md {
    @apply hidden md:table-cell;
  }

  .table-cell-lg {
    @apply hidden lg:table-cell;
  }

  .table-cell-xl {
    @apply hidden xl:table-cell;
  }

  .actions-approve {
    @apply px-2 cursor-pointer text-green-600 hover:text-green-900 disabled:opacity-50;
  }

  .actions-danger {
    @apply px-2 cursor-pointer text-red-600 hover:text-red-900 disabled:opacity-50;
  }

  .actions-primary {
    @apply px-2 cursor-pointer text-gray-600 hover:text-gray-900 disabled:opacity-50;
  }
  // End Table

  // GRID
  .grid-1-2 {
    @apply grid grid-cols-1 md:grid-cols-2 gap-2;
  }

  .grid-1-3 {
    @apply grid grid-cols-1 md:grid-cols-3 gap-4;
  }

  .grid-1-2-3 {
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2;
  }

  .grid-1-2-4 {
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2;
  }

  .grid-1-3-5 {
    @apply grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-2;
  }

  .grid-1-3-6 {
    @apply grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-2;
  }

  .grid-1-2-4-6 {
    @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-2;
  }

  .details-title {
    @apply font-medium leading-6 text-gray-900 text-lg;
  }

  .details-subtitle {
    @apply mt-1 text-gray-600 text-sm;
  }

  .details-card {
    @apply shadow sm:rounded-md sm:overflow-hidden;
  }

  .details-card-padding {
    @apply px-4 py-5 sm:p-6 space-y-6;
  }
  // GRID

  // TABS
  nav.tabs {
    @apply sticky flex border-b-2 border-gray-200 text-xs overflow-auto;
  }

  nav.tabs a {
    min-width: 150px;
    @apply py-2 px-4 font-medium text-center text-gray-700 hover:text-gray-900 hover:underline focus:outline-none uppercase;
  }

  nav.tabs a.active,
  .tab-active {
    @apply text-yellow-300 bg-gray-800 rounded-t-md;
  }

  .secondary-tab {
    @apply p-1 flex items-center gap-2 text-sm font-medium text-gray-700 cursor-pointer disabled:opacity-50;
  }

  .secondary-tab-active {
    @apply border-b-2 border-gray-800;
  }
  // TABS

  // DIALOGS
  .dialog-wrapper {
    @apply fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden 
      overflow-y-auto outline-none focus:outline-none;
  }

  .dialog-inner-wrapper {
    @apply relative w-auto max-w-6xl mx-auto my-6;
  }

  .dialog-content {
    @apply relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none;
  }

  .dialog-header {
    @apply p-4 flex items-center justify-between border-b border-solid rounded-t border-gray-200;
  }

  .dialog-header-close {
    @apply px-2 text-red-500 border border-red-500 hover:opacity-60 rounded-full;
  }

  .dialog-body {
    @apply p-6 relative flex-auto;
  }

  .dialog-footer {
    @apply p-6 flex items-center justify-end border-t border-solid rounded-b border-gray-200 gap-3;
  }

  .dialog-target {
    @apply fixed inset-0 z-40 bg-black opacity-25;
  }
  // DIALOGS

  .card {
    @apply p-6 shadow rounded-md text-sm;
  }

  .nav-icon {
    @apply block py-1 md:py-2 text-lg text-gray-500 items-center rounded-md 
      md:text-xs md:mx-auto hover:text-yellow-500 hover:bg-gray-200 focus:outline-none;
  }

  .nav-icon.active,
  .dropdown-link.active {
    @apply bg-gray-800 text-yellow-300;
  }

  .nav-link {
    @apply p-2 hover:text-accent hover:bg-gray-100 rounded;
  }

  .nav-link.active {
    @apply bg-gray-100 text-secondary hover:text-accent;
  }

  .main-nav-item {
    @apply px-2 m-auto w-full flex flex-row md:flex-col justify-start md:justify-center items-center text-center;
  }

  .main-nav-svg {
    @apply w-5 h-5 md:w-8 md:h-8 mx-auto stroke-current;
  }

  .cancel-link {
    @apply text-sm text-gray-600 hover:text-red-500 underline;
  }

  a.disabled {
    @apply pointer-events-none;
  }

  .chart-panel {
    @apply p-4 grid items-center justify-items-center shadow;
  }

  .stats-panel {
    @apply px-3 py-1 flex items-center justify-between rounded shadow gap-2;
  }

  .stats-panel-image {
    @apply p-2 grid items-center justify-items-center bg-gray-700 text-primary rounded-md;
  }

  .stats-panel-svg {
    @apply w-5 h-5 mx-auto stroke-current;
  }

  .stats-panel-title {
    @apply font-medium text-sm text-gray-500 uppercase;
  }

  .stats-panel-value {
    @apply font-bold text-xl text-right;
  }
}
